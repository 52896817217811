<template>
  <v-data-table
    :headers="headers"
    :items="budgets"
    :items-per-page="10"
    class="elevation-1"
    :loading="isLoading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:item.userId="{ item }">
      <base-profile-avatar :size="50" :user="hashUsers[item.employeeId]" />
    </template>
    <template v-slot:item.year="{ item }">
      <v-chip color="primary" outlined>
        {{ item.year }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(option, i) in rowItemActions"
            :key="i"
            @click="option.action(item)"
          >
            <v-list-item-title>{{ option.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'BudgetsTable',

  components: {
    BaseProfileAvatar: () => import('@/components/BaseProfileAvatar'),
  },

  data: () => ({
    headers: [
      {
        text: 'User',
        align: 'start',
        sortable: true,
        value: 'userId',
      },
      {
        text: 'Year',
        align: 'start',
        sortable: false,
        value: 'year',
      },
      { text: 'Amount', value: 'amount' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),

  created() {
    this.bindBudgetsRef();
  },

  computed: {
    ...mapState('budgets', ['isLoading', 'budgets']),
    ...mapGetters('users', ['hashUsers']),
    ...mapGetters('auth', ['isAdmin']),
    rowItemActions() {
      return this.isAdmin
        ? [
            {
              label: 'Edit',
              action: (budget) => this.$emit('onEdit', 'budgets', budget.id),
            },
            {
              label: 'Delete',
              action: (budget) => this.$emit('onDelete', 'budgets', budget.id),
            },
          ]
        : [];
    },
  },

  methods: {
    ...mapActions('budgets', ['bindBudgetsRef']),
  },
};
</script>
